import axios from 'axios'

const airtable = 'https://api.airtable.com/v0/'
const app_key = 'keyg5qCoE1y2oFmrh'
const max_records = 100
const app_id = `appKruKgUNFQmgwFr/Small%20Businesses?view=ABC`

export default {
  get() {
    return axios.get(`${airtable}${app_id}`, {
      headers: { Authorization: `Bearer ${app_key}`}
    })
  },
  getPage(offset) {
    return axios.get(`${airtable}${app_id}&offset=${offset}`, {
      headers: { Authorization: `Bearer ${app_key}`}
    })
  }
}
