//
//
//
//
//
//
//
//
//
//
//

import Sidebar from './Shared/Sidebar.vue'
import _ from 'lodash'

export default {
  name: 'Home',
  components: {
    Sidebar
  },
  data () {
    return {
    }
  },
  mounted () {
    this.$ma.trackEvent({action: 'Homepage View'})
  }
}
