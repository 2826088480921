import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'

Vue.use(Vuex)

const state = {
  filters: {
    'Business Name': {
      asc: true
    },
    'City': {
      asc: true
    },
    'State': {
      asc: true
    },
    'Added': {
      asc: true
    },
    'Country': {
      asc: true
    }
  },
  cos: [],
  expanded: false
}

const mutations = {
  populateCos(state, cos) {
    state.cos = _.orderBy(cos, [`fields.Name`], ['asc'])
  },
  pushCos(state, cos) {
    state.cos = _.union(state.cos, cos)
    state.cos = _.orderBy(state.cos, [`fields.Name`], ['asc'])
  },
  toggleSort(state, sort) {
    if(state.filters[sort].asc) {
      state.filters[sort].asc = false
      return state.cos = _.orderBy(state.cos, [`fields.${sort}`], ['desc'])
    } else {
      state.filters[sort].asc = true
      return state.cos = _.orderBy(state.cos, [`fields.${sort}`], ['asc'])
    }
  },
  toggleMobile(state) {
    state.expanded = !state.expanded
  },
  closeMobile(state) {
    state.expanded = false
  }
}

export default new Vuex.Store({
  state,
  mutations
})
