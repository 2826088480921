//
//
//
//
//
//
//
//
//

import Home from './components/Home.vue'

export default {
  name: 'app',
  components: {
    Home,
  },
  computed: {
    className() {
      return 'is-' + this.$route.name
    },
    hoverActive() {
      if(this.$store.state.hover_active) {
        return true
      }
    }
  }
}
