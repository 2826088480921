//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HeaderFill from '../Shared/Header.vue'
import Item from '../Shared/Item.vue'
import data from '../../data/data'

export default {
  name: 'Home',
  components: {
    Item,
    HeaderFill
  },
  data () {
    return {
      cos: []
    }
  },
  methods: {
    toggleSort(sort) {
      this.$store.commit('toggleSort', sort)
    },
    getNext(r) {
      if (!r.data.offset) return
      data.getPage(r.data.offset).then((response) => {
        this.pushArray(response)
        return this.getNext(response)
      })
    },
    pushArray(response) {
      this.$store.commit('pushCos', response.data.records)
    }
  },
  created() {
    data.get().then((response) => {
      this.$store.commit('populateCos', response.data.records)
      return this.getNext(response)
    })
  }
}
