import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import store from './store'
import Headful from 'vue-headful';
import VueMultianalytics from 'vue-multianalytics'

import Home from './components/Home'
import About from './components/Route/About'
import Contact from './components/Route/Contact'
import List from './components/Route/List'

Vue.use(VueRouter)
Vue.component('vue-headful', Headful);
Vue.config.productionTip = false

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Home,
      name: 'home',
      children: [
        {
          path: '',
          component: List,
          name: 'list',
          meta: {
            analytics: 'List'
          }
        },
        {
          path: 'contact',
          component: Contact,
          name: 'contact',
          meta: {
            analytics: 'Contact'
          }
        },
        {
          path: 'about',
          component: About,
          name: 'about',
          meta: {
            analytics: 'About'
          }
        }
      ],
      meta: {
        analytics: 'Home'
      }
    }
  ]
})

let mixpanelConfig = {
  token: '23bfb0a60e7f7004ce7fcfc98503f78d'
}

Vue.use(VueMultianalytics, {
  modules: {
    mixpanel: mixpanelConfig
  },
  routing: {
    vueRouter: router, //  Pass the router instance to automatically sync with router (optional)
    preferredProperty: 'page' // By default 'path' and related with vueRouter (optional)
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
