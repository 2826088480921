//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import moment from 'moment'

export default {
  name: 'Item',
  components: {},
  props: [
    'item'
  ],
  data () {
    return {
    }
  },
  computed: {
    date () {
      return moment(this.item.fields.Added).format('MM/DD/YYYY')
    },
    city () {
      if (!this.item.fields['City']) return '-'
      return this.item.fields['City'][0]
    },
    state () {
      if (!this.item.fields['State']) return '-'
      return this.item.fields['State'][0] || '-'
    },
    country () {
      if (!this.item.fields['Country']) return '-'
      return this.item.fields['Country'][0] || '-'
    },
    zip () {
      return this.item.fields.Zipcode || '-'
    }
  },
  methods: {
    go (url) {
      this.$ma.trackEvent({action: 'Click Item', properties: {'Business': this.item.fields['Business Name']}})
      return window.open(url, '_blank')
    }
  }
}
